import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './assets/css/reset.css'
import proportion from 'vue-proportion-directive'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import wow from 'wowjs'
import AOS from 'aos'
import 'aos/dist/aos.css'
// import AOS from 'aos/dist/aos.js'


import animated from 'animate.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import $ from 'jquery'
window.jquery = $
window.jQuery = $
window.$ = $
Vue.prototype.$AOS = AOS
Vue.prototype.$wow = wow
Vue.prototype.$ = jquery;


import VueAnimateNumber from 'vue-animate-number'


import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

Viewer.setDefaults({
    'inline': false, 
    'button': true, 
    'navbar': true, 
    'title': false, 
    toolbar: {
        zoomIn: 0,
        zoomOut: 0,
        oneToOne: 0,
        reset: 0,
        prev: {
            show: 3,
            size: 'large',
        },
        play: 0,
        next: {
            show: 3,
            size: 'large',
        },
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
    },
    "backdrop": false,
    'tooltip': false, 
    'movable': false, 
    'zoomable': false, 
    'rotatable': false, 
    'scalable': false, 
    'transition': true, 
    'fullscreen': true, 
    'keyboard': true, 
    // 'url': 'data-source' 
})



Vue.use(VueAnimateNumber)
Vue.use(VueAwesomeSwiper);

Vue.use(wow)
Vue.use(AOS)
Vue.use(animated)
Vue.prototype.$video = Video
Vue.use(proportion);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
axios.defaults.baseURL = 'https://127.0.0.1:3333'

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')

