<template>
  <div id="app">
     <router-view />
  </div>
</template>
<script>
export default {
 //App.vue
  mounted() {
      if (this._isMobile()) {
        //alert("手机端");
        //this.$router.replace('https://m.starmaxintl.com');
		window.location.href = "https://m.starmaxintl.com"
      } else {
       // alert("pc端");
        this.$router.replace('/home');
      }
    },
    methods: {
      //App.vue
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      }
  }
}
</script>