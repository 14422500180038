import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'   // 重定向，输入/的时候直接进入首页
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import('../views/login.vue')
  // },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('../views/category/category.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service/service.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/project/project.vue')
  },
  {
    path: '/project-detail',
    name: 'project-detail',
    component: () => import('../views/project-detail/project-detail.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/blog/blog.vue')
  },
  {
    path: '/blog-detail',
    name: 'blog-detail',
    component: () => import('../views/blog-detail/blog-detail.vue')
  },
  {
    path: '/blog-group',
    name: 'blog-group',
    component: () => import('../views/blog-group/blog-group.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/about.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact/contact.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/faq/faq.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
